<template>
    <div>
        <!-- begin::Entry -->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container-fluid py-8">
                <!--begin::Education-->
                <div class="d-flex flex-row">
                    <UserNav activePage="livestreamtest" activeTab="4"></UserNav>
                    <!--begin::Content-->
                    <div class="flex-row-fluid ml-lg-12">

                        <div>
                            <!--begin::Container-->
                            <div>
                                <div>
                                    <!--begin::Section-->
                                    <div>
                                        <loading :active.sync="visible" :can-cancel="true">
                                            <div class="loader__container">
                                                <img class="w100 h100" src="/media/logos/ingomu-preloader.gif" />
                                            </div>
                                        </loading>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <!--begin::Card-->
                                                <div class="card card-custom card-stretch gutter-b">
                                                    <!--begin::Body-->
                                                    <div class="card-body pt-4">
                                                        <!--begin::User-->
                                                        <div class="d-flex align-items-center mb-10">
                                                            <div
                                                                class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                                                <img class="w100 h100" :src="photo" />
                                                            </div>
                                                            <div>
                                                                <a href="#"
                                                                    class="font-weight-bold font-size-h5 text-dark text-hover-primary">{{
                                                                        name
                                                                    }}</a>
                                                                <div class="text-dark">{{ title }}</div>
                                                                <div class="mt-2">
                                                                    <button class="btn btn-xs" :class="{
                                                                        'btn btn-danger': !livestreamActive,
                                                                        'btn btn-success': livestreamActive,
                                                                    }">
                                                                        {{ statusText }}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!--end::User-->
                                                        <!--end::Contact-->
                                                        <div>
                                                            <!--begin::Charts Widget 3-->
                                                            <div class="card card-custom card-stretch gutter-b card-border">
                                                                <!--begin::Body-->
                                                                <div class="card-body">
                                                                    <!--begin::Container-->
                                                                    <div>
                                                                        <!--begin::Body-->
                                                                        <div id="scrollChatContainer"
                                                                            ref="scrollChatContainer" class="h-300">
                                                                            <!--begin::Item-->
                                                                            <div class="d-flex py-5"
                                                                                v-for="chat in sessionChatsTest"
                                                                                :key="chat.user.datetime">
                                                                                <!--begin::Symbol-->
                                                                                <div
                                                                                    class="symbol symbol-square symbol-50 mr-3">
                                                                                    <span class="symbol symbol-50">
                                                                                        <img :src="imageSource(chat.user.profileimage)"
                                                                                            alt="" />
                                                                                    </span>
                                                                                </div>
                                                                                <!--end::Symbol-->
                                                                                <!--begin::Info-->
                                                                                <div
                                                                                    class="d-flex flex-column flex-row-fluid">
                                                                                    <!--begin::Info-->
                                                                                    <div
                                                                                        class="d-flex align-items-center flex-wrap">
                                                                                        <a href="javascript:void(0)"
                                                                                            class="text-dark text-hover-primary mb-1 font-size-lg font-weight-bolder pr-6">{{
                                                                                                chat.user.firstname
                                                                                            }}</a>
                                                                                    </div>
                                                                                    <span
                                                                                        class="text-dark font-size-sm font-weight-normal pt-1">{{
                                                                                            chat.message }}</span>
                                                                                    <!--end::Info-->
                                                                                </div>
                                                                                <!--end::Info-->
                                                                            </div>
                                                                            <!--end::Item-->
                                                                        </div>
                                                                        <!--end::Body-->
                                                                    </div>
                                                                    <!--end::Container-->
                                                                    <!--begin::Separator-->
                                                                    <div class="separator separator-solid mt-5 mb-4"></div>
                                                                    <!--end::Separator-->
                                                                    <!--begin::Editor-->
                                                                    <form class="position-relative">
                                                                        <textarea
                                                                            class="form-control border-0 p-0 pr-10 resize-none w-80"
                                                                            rows="2" placeholder="Type a message"
                                                                            v-model="messageForm.message"></textarea>
                                                                        <div
                                                                            class="position-absolute top-0 right-0 mt-n1 mr-n2">
                                                                            <div class="mr-3"></div>
                                                                            <div>
                                                                                <button type="button" class="
                                                                btn btn-primary btn-md
                                                                text-uppercase
                                                                font-weight-bold
                                                                chat-send
                                                                py-2
                                                                px-6
                                                                " @click="addMessage()">
                                                                                    Send
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                    <!--edit::Editor-->
                                                                </div>
                                                                <!--end::Body-->

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--end::Body-->
                                                </div>
                                                <!--end::Card-->
                                            </div>
                                            <div class="col-lg-6">
                                                <div>
                                                    <!--begin::Header-->
                                                    <div>
                                                        <h3 class="card-title align-items-end flex-column">
                                                            <span class="card-label font-weight-bolder text-dark fs-15">
                                                                <h3 class="text-dark fs-15"></h3>
                                                            </span>
                                                        </h3>
                                                    </div>
                                                    <!-- end::Header -->
                                                    <!--begin::Body-->
                                                    <div class="smartphone">
                                                        <div class="content w-100 h-100 p-0">
                                                            <div v-show="livestreamActive == true">
                                                                <video ref="videoPlayer" class="video-js"></video>
                                                                <div class="widget--profile widget--profile-video">
                                                                    <img class="widget--profile__image_small" :src="photo"
                                                                        alt="">
                                                                    <div class="live-box">Live</div>
                                                                </div>
                                                            </div>
                                                            <div v-show="livestreamActive == false"
                                                                class="no-livestream w-100 h-100 overflow-hidden">
                                                                <div class="no-livestream-img">
                                                                    <div class="text-center">
                                                                        <img class="widget--profile__image_medium"
                                                                            :src="photo" alt="">
                                                                        <br /><br />
                                                                        <p class="offline-text">Livestream is offline</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="card-body h-100">
                                    <div id="videocontainer" class="col-md-8 offset-md-2">
                                        <video ref="videoPlayer" class="video-js"></video>
                                    </div>
                                </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Section-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { registerIVSTech } from "amazon-ivs-player";
import { mapGetters } from "vuex";
import moment from "moment";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import defaultProfileImageMixin from "@/mixins/defaultProfileImage";
import PerfectScrollbar from "perfect-scrollbar";
import UserNav from "@/view/layout/header/UserNav.vue";
const fmt = "YYYY-MM-DD HH:mm:ss";

export default {
    components: {
        Loading: VueLoading,
        UserNav
    },
    mixins: [defaultProfileImageMixin],
    data() {
        return {
            visible: false,
            statusText: "Offline",
            tabIndex: 0,
            player: null,
            videoSource: "",
            videoOptions: {
                autoplay: true,
                muted: false,
                controls: true,
                techOrder: ["AmazonIVS"],
                height: "300",
                aspectRatio: "16:9",
                responsive: true,
                fluid: true,
                bigPlayButton: false,
                controlBar: {
                    fullscreenToggle: false,
                    playToggle: false,
                    pictureInPictureToggle: false
                }
            },
            default_photo: "media/users/blank.png",
            current_photo: null,
            next_photo: null,
            current_name: null,
            next_name: null,
            current_title: null,
            next_title: null,
            sessionId: null,
            coachListId: null,
            messageForm: {
                message: "",
            },
            livestreamActive: false
        };
    },
    beforeUnmount() {
        // Destroy the player instance
        if (this.player) {
            this.player.dispose();
        }
    },
    componentDidMount() {
        const rightScroll = new PerfectScrollbar("#scrollChatContainer");
    },
    mounted() {
        this.$store.dispatch("liveUpateRecordingTestConfigurations");
        this.$store.commit("CHANGE_TABINDEX", { tabIndex: 3 });
        this.$store.commit("CHANGE_ACTIVETAB", { activeTab: 18 });
        registerIVSTech(videojs, {
            wasmWorker: this.createAbsolutePath(
                "/assets/amazon-ivs-wasmworker.min.js"
            ),
            wasmBinary: this.createAbsolutePath(
                "/assets/amazon-ivs-wasmworker.min.wasm"
            ),
        });

        this.player = videojs(this.$refs.videoPlayer, this.videoOptions);
    },
    methods: {
        createAbsolutePath(assetPath) {
            return new URL(assetPath, document.URL).toString();
        },
        setActiveTab(event) {
            const tab = event.target.closest('[role="tablist"]');
            const links = tab.querySelectorAll(".navi-link");
            // remove active tab links
            for (let i = 0; i < links.length; i++) {
                links[i].classList.remove("active");
            }
            // set clicked tab index to bootstrap tab
            this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

            // set current active tab
            event.target.classList.add("active");
        },
        addMessage() {
            if (this.messageForm.message === "") {
                Swal.fire({
                    title: "",
                    text: "You must enter a message!",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                    heightAuto: true,
                });
            } else {
                if (this.coachListId !== null && this.sessionId !== null) {
                    const newmessage = this.messageForm.message;
                    this.$store.dispatch("addNewMessage", {
                        sessionid: this.sessionId,
                        coach_list_id: this.coachListId,
                        text: newmessage,
                    });
                }
                this.messageForm.message = "";
            }
        },
        updateScroll() {
            this.$nextTick(
                () =>
                (this.$refs.scrollChatContainer.scrollTop =
                    this.$refs.scrollChatContainer.scrollHeight)
            );
        },

    },
    watch: {
        livestreamTest(newValue, oldValue) {
            if (newValue[0] !== undefined) {
                this.videoSource = newValue[0].playbackUrl
                this.current_photo = newValue[0].coach.profileimage
                this.current_name = newValue[0].coach.first + " " + newValue[0].coach.last
                this.current_title = newValue[0].title
                this.coachListId = newValue[0].coach_list_id
                this.sessionId = newValue[0].sessionid
                this.player.src({
                    src: this.videoSource,
                    type: "application/x-mpegURL"
                });
                this.statusText = "Online";
                this.nextLivestreamTime = ""
                this.next_name = null
                this.next_photo = null
                this.next_title = null
                this.livestreamActive = true
                this.$store.dispatch("setUserOnline", {
                    coachListId: newValue[0].coach_list_id,
                    sessionId: newValue[0].sessionid
                })
            } else {
                this.player.pause();
                this.player.reset();
                this.player.currentTime(0);
                this.current_name = null
                this.current_photo = null
                this.current_title = null
                this.livestreamActive = false
                this.coachListId = null
                this.sessionId = null
                this.$store.dispatch("getNextLivestream")
            }
        },
        sessionChatsTest(value) {
            this.updateScroll();
            if (value.length > 0) {
                this.chatShow = true;
            }
        },
        sessionId(newValue, oldValue) {
            if (newValue != null) {
                this.$store.dispatch("getSessionChatsTest", {
                    coachListId: this.coachListId,
                    sessionId: newValue,
                });
            } else {
                this.$store.dispatch("clearSessionChatsTest")
            }
        },
    },
    computed: {
        ...mapGetters([
            "playbackUrl",
            "sessionChatsTest",
            "nextLivestream",
            "livestreamTest"
        ]),
        photo() {
            var photoToDisplay = this.default_photo
            if (this.current_photo != null) {
                photoToDisplay = this.current_photo
            } else if (this.next_photo != null) {
                photoToDisplay = this.next_photo
            }
            return photoToDisplay
        },
        name() {
            var nameToDisplay = ""
            if (this.current_name != null) {
                nameToDisplay = this.current_name
            } else if (this.next_name != null) {
                nameToDisplay = this.next_name
            }
            return nameToDisplay
        },
        title() {
            var titleToDisplay = ""
            if (this.current_title != null) {
                titleToDisplay = this.current_title
            } else if (this.next_title != null) {
                titleToDisplay = this.next_title
            }
            return titleToDisplay
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch("setUserOffline", {
            coachListId: this.coachListId,
            sessionId: this.sessionId
        })
        next()
    }
};
</script>

<style scoped>
.p-0 {
    padding: 0;
}

.width420 {
    width: 420px;
}

/* hide default vue-bootstrap tab links */
.hide-tabs>div:not(.tab-content) {
    display: none;
}

#videocontainer {
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    left: 0;
    right: 0;
    max-height: 400px;
}

.flip-card {
    font-size: 2rem !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.w350 {
    width: 350px;
}

.h-400 {
    height: 400px;
}

.h-300 {
    height: 300px;
}

.card-border {
    border: 1px solid #EBEDF3;
}

#scrollChatContainer {
    position: relative;
    height: 350px;
    overflow: auto;
}

.w-80 {
    width: 80%;
}

.video-js,
.vjs-tech {
    position: absolute !important;
    height: 100% !important;
    border-radius: none !important;
}

.widget--profile__image_small {
    margin-right: 15px;
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.widget--profile__image_medium {
    margin-right: 15px;
    min-width: 60px;
    max-width: 60px;
    min-height: 60px;
    max-height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.widget--profile-video {
    position: absolute;
    top: 15px;
    left: 30px;
    text-align: center;
    display: flex;
}

.live-box {
    background-color: #3b5998;
    color: #ffffff;
    padding: 5px;
    font-size: 12px;
    border-radius: 15%;
}

.no-livestream {
    background-color: #3b5998;
}

.no-livestream-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.offline-text {
    color: #ffffff;
    font-size: 16x;
}


/* The device with borders */
.smartphone {
    position: relative;
    width: 360px;
    height: 600px;
    margin: auto;
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
    width: 360px;
    height: 640px;
    background: white;
}
</style>

